<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      A key on the keyboard that is used to move several spaces at a time or to move to a particular position in a document.
    </p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

.hello {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 20px;
  max-width: 600px; /* Restrict the width */
  margin: 20px auto; /* Center the div */
}

h1 {
  color: #61dafb;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 2.5em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

p {
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1.2em;
  line-height: 1.5;
  margin: 0;
}
</style>