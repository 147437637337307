<template>
  <div id="app">
    <div class="vanta-background" ref="vantaRef">
      <navbar></navbar>
      <div class="container">
        <div class="centered-content">
          <img alt="Vue logo" src="./assets/logo.png" class="vw-img">
          <HelloWorld msg="Tab"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import BIRDS from 'vanta/dist/vanta.waves.min'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  mounted() {
    this.vantaEffect = BIRDS({
      el: this.$refs.vantaRef
    })
  },
  beforeUnmount() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy()
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
  padding-top: 0;
}

.vanta-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.centered-content {
  text-align: center;
  z-index: 1;
}

.vw-img {
  width: 30vw;
  margin-bottom: 20px;
}
</style>